import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import styles from './header.module.css';

const Hamburger = ( { active, className, onClick, onFocus } ) => {
	const hamburgerClassNames = useMemo( () => ( {
		enter: styles.hamburgerEnter,
		enterActive: styles.hamburgerEnterActive,
		enterDone: styles.hamburgerEnterDone,
		exit: styles.hamburgerExit,
		exitActive: styles.hamburgerExitActive,
		exitDone: styles.hamburgerExitDone
	} ), [] );

	return (
		<CSSTransition
			classNames={ hamburgerClassNames }
			in={ active }
			key="hamburger"
			timeout={ 0 }
		>
			<div
				className={ `${ styles.hamburger } ${ className } ${ active ? styles.active : '' }` }
				onMouseDown={ onClick } // mouseDown fires before focus event
				onFocus={ onFocus }
				tabIndex="0"
			>
				<div className={ styles.hamburgerBox }>
					<div className={ styles.hamburgerInner } />
				</div>
			</div>
		</CSSTransition>
	);
};

Hamburger.defaultProps = {
	className: '',
	onClick: () => null
};

Hamburger.propTypes = {
	active: PropTypes.bool,
	className: PropTypes.string,
	onClick: PropTypes.func,
	onFocus: PropTypes.func
};

export default Hamburger;
