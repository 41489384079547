import React, { useCallback, useState } from 'react';
import styles from './header.module.css';

import Hamburger from './Hamburger';
import SiteNavigation from '../SiteNavigation';
import MobileMenu from '../MobileMenu';
import { Link } from 'gatsby';

const Header = () => {
	const [ mobileMenu, setMobileMenu ] = useState( false );

	const toggleMobileMenu = useCallback( () => {
		setMobileMenu( prev => !prev );

		if ( document.body.style.overflow !== 'hidden' ) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'initial';
		}
	}, [] );

	const handleTitleFocus = useCallback( () => {
		if ( mobileMenu ) {
			toggleMobileMenu();
		}
	}, [ mobileMenu, toggleMobileMenu ] );

	const handleHamburgerClick = useCallback( e => {
		e.preventDefault();
		e.stopPropagation();
		toggleMobileMenu();
	}, [ toggleMobileMenu ] );

	const handleHamburgerFocus = useCallback( () => {
		if ( !mobileMenu ) {
			toggleMobileMenu();
		}
	}, [ mobileMenu, toggleMobileMenu ] );

	return (
		<header className={ styles.header }>
			<div className={ styles.wrapper }>
				<Link
					onFocus={ handleTitleFocus }
					to="/"
				>
					<h1>Guillaume Muller</h1>
				</Link>
				<Hamburger
					active={ mobileMenu }
					onClick={ handleHamburgerClick }
					onFocus={ handleHamburgerFocus }
				/>
				<SiteNavigation className={ styles.siteNavigation } />
			</div>
			<MobileMenu
				close={ toggleMobileMenu }
				open={ mobileMenu }
			/>
		</header>
	);
};

export default Header;
