import React from 'react';
import PropTypes from 'prop-types';

import Header from '../Header';
import Footer from '../Footer';

import styles from './layout.module.css';

const Layout = ( { children } ) => {
	return (
		<div className={ styles.layout }>
			<Header />
			<main>
				{ children }
			</main>
			<Footer />
		</div>
	);
};

Layout.propTypes = { children: PropTypes.node.isRequired };

export default Layout;
