import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBandcamp, faFacebookF, faInstagram, faSoundcloud, faYoutube } from '@fortawesome/free-brands-svg-icons';

import useSiteIdentity from '../../hooks/useSiteIdentity';

import styles from './social-links.module.css';

const SocialLinks = ( { className, disabled } ) => {
	const { smLinks: { facebook, instagram, youtube, soundcloud, bandcamp } } = useSiteIdentity();

	return (
		<div className={ `${ styles.socialLinks } ${ className }` }>
			{ facebook
				? <a
					href={ facebook }
					rel="noreferrer noopener"
					tabIndex={ disabled ? -1 : 0 }
					target="_blank"
				>
					<FontAwesomeIcon icon={ faFacebookF } className={ styles.facebook } title="Facebook" />
				</a>
				: null
			}
			{ instagram
				? <a
					href={ instagram }
					rel="noreferrer noopener"
					tabIndex={ disabled ? -1 : 0 }
					target="_blank"
				>
					<FontAwesomeIcon icon={ faInstagram } className={ styles.instagram } title="Instagram" />
				</a>
				: null
			}
			{ youtube
				? <a
					href={ youtube }
					rel="noreferrer noopener"
					tabIndex={ disabled ? -1 : 0 }
					target="_blank"
				>
					<FontAwesomeIcon icon={ faYoutube } className={ styles.youtube } title="YouTube" />
				</a>
				: null
			}
			{ soundcloud
				? <a
					href={ soundcloud }
					rel="noreferrer noopener"
					tabIndex={ disabled ? -1 : 0 }
					target="_blank"
				>
					<FontAwesomeIcon icon={ faSoundcloud } className={ styles.soundcloud } title="Soundcloud" />
				</a>
				: null
			}
			{ bandcamp
				? <a
					href={ bandcamp }
					rel="noreferrer noopener"
					tabIndex={ disabled ? -1 : 0 }
					target="_blank"
				>
					<FontAwesomeIcon icon={ faBandcamp } className={ styles.bandcamp } title="Bandcamp" />
				</a>
				: null
			}
		</div>
	);
};

SocialLinks.defaultProps = { className: '' };

SocialLinks.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool
};

export default SocialLinks;
