import React, { memo, useCallback, useLayoutEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { CSSTransition } from 'react-transition-group';

import SiteNavigation from '../SiteNavigation';
import SocialLinks from '../SocialLinks';

import styles from './mobile-menu.module.css';

const MobileMenu = ( { close, open } ) => {
	useLayoutEffect( () => {
		const listener = () => {
			if ( open ) {
				close();
			}
		};

		window.addEventListener( 'resize', listener );

		return () => {
			window.removeEventListener( 'resize', listener );
		};
	}, [ close, open ] );

	const handleLinkClick = useCallback( e => {
		e.preventDefault();
		e.persist();
		close();
		setTimeout( () => {
			navigate( e.target.href );
		}, 200 );
	}, [ close ] );

	const mobileMenuClassNames = useMemo( () => ( {
		enter: styles.mobileMenuEnter,
		enterActive: styles.mobileMenuEnterActive,
		enterDone: styles.mobileMenuEnterDone,
		exit: styles.mobileMenuExit,
		exitActive: styles.mobileMenuExitActive,
		exitDone: styles.mobileMenuExitDone
	} ), [] );

	const dimmerClassNames = useMemo( () => ( {
		enter: styles.dimmerEnter,
		enterActive: styles.dimmerEnterActive,
		enterDone: styles.dimmerEnterDone,
		exit: styles.dimmerExit,
		exitActive: styles.dimmerExitActive,
		exitDone: styles.dimmerExitDone
	} ), [] );

	return (
		<>
			<CSSTransition
				classNames={ mobileMenuClassNames }
				in={ open }
				key="mobileMenu"
				timeout={ 0 }
			>
				<div className={ `${ styles.mobileMenu }` }>
					<SiteNavigation
						className={ styles.siteNavigation }
						onLinkClick={ handleLinkClick }
						disabled={ !open }
					/>
					<SocialLinks className={ styles.socialLinks } disabled={ !open } />
				</div>
			</CSSTransition>
			<CSSTransition
				classNames={ dimmerClassNames }
				in={ open }
				onFocus={ close }
				mountOnEnter
				unmountOnExit
				tabIndex="0"
				timeout={ { enter: 500, exit: 0 } }
			>
				<div className={ styles.dimmer } onClick={ close } />
			</CSSTransition>
		</>
	);
};

MobileMenu.propTypes = {
	close: PropTypes.func,
	open: PropTypes.bool
};

export default memo( MobileMenu );
