import { useStaticQuery, graphql } from 'gatsby';

const useSiteIdentity = ( ) => {
	let { file: { childMarkdownRemark: { frontmatter: SiteIdentity } } } = useStaticQuery( graphql`
		query SiteIdentity {
			file(relativePath: {eq: "site-identity.md"}) {
				childMarkdownRemark {
					frontmatter {
						smLinks {
							facebook
							instagram
							youtube
							soundcloud
							bandcamp
						}
					}
				}
			}
		}
	` );

	return SiteIdentity;
};

export default useSiteIdentity;
