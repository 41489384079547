import React from 'react';

import SocialLinks from '../SocialLinks';

import styles from './footer.module.css';

const Footer = () => {
	return (
		<footer className={ styles.footer }>
			<SocialLinks className={ styles.socialLinks } />
			<div className={ styles.copyright }>
				Copyright &copy;&nbsp;{ new Date().getFullYear() }&nbsp;&middot;&nbsp;Guillaume Muller Music
			</div>
		</footer>
	);
};

export default Footer;
