import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import NavLink from './NavLink';

import styles from './site-navigation.module.css';

const SiteNavigation = ( { className, disabled, onLinkClick } ) => {
	const { allFile: { nodes } } = useStaticQuery( graphql`
		query {
			allFile(filter: {relativeDirectory: {eq: "pages"}}) {
				nodes {
					childMarkdownRemark {
						frontmatter {
							hidePage
						}
					}
					name
				}
			}
		}
	` );

	const hiddenPages = useMemo( () => nodes.reduce( ( acc, {
		childMarkdownRemark: { frontmatter: { hidePage } },
		name
	} ) => ( { ...acc, [ name ]: hidePage } ), {} ), [ nodes ] );

	return (
		<nav className={ `${ styles.siteNavigation } ${ className }` }>
			{ console.log( hiddenPages )}
			<ul>
				<li>
					<NavLink
						disabled={ disabled }
						onClick={ onLinkClick }
						to="/"
					>
						Home
					</NavLink>
				</li>
				{ hiddenPages.about
					? null
					: <li>
						<NavLink
							disabled={ disabled }
							onClick={ onLinkClick }
							to="/about"
						>
							About
						</NavLink>
					</li>
				}
				{ hiddenPages.music
					? null
					: <li>
						<NavLink
							disabled={ disabled }
							onClick={ onLinkClick }
							to="/music"
						>
						Music
						</NavLink>
					</li>
				}
				{ hiddenPages.learn
					? null
					: <li>
						<NavLink
							disabled={ disabled }
							onClick={ onLinkClick }
							to="/learn"
						>
						Transcriptions/Lessons
						</NavLink>
					</li>
				}
				{ hiddenPages.shows
					? null
					: <li>
						<NavLink
							disabled={ disabled }
							onClick={ onLinkClick }
							to="/shows"
						>
						Shows
						</NavLink>
					</li>
				}
				{ hiddenPages.contact
					? null
					: <li>
						<NavLink
							disabled={ disabled }
							onClick={ onLinkClick }
							to="/contact"
						>
						Contact
						</NavLink>
					</li>
				}
			</ul>
		</nav>
	);
};

SiteNavigation.defaultProps = {
	className: '',
	onLinkClick: () => null
};

SiteNavigation.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	onLinkClick: PropTypes.func
};

export default SiteNavigation;
