import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styles from './site-navigation.module.css';

const NavLink = ( { children, className, disabled, onClick, to } ) => {
	const LinkRef = useRef( null );

	const isActive = useCallback( ( { isCurrent, isPartiallyCurrent } ) => {
		if ( to === '/' && isCurrent || to !== '/' && isPartiallyCurrent ) {
			if ( LinkRef.current ) {
				LinkRef.current.tabIndex = '-1';
			}

			return { className: styles.active };
		}

		if ( LinkRef.current ) {
			LinkRef.current.tabIndex = disabled ? '-1' : '0';
		}

		return null;
	}, [ disabled, to ] );

	return (
		<Link
			className={ className }
			activeClassName={ styles.active }
			getProps={ isActive }
			onClick={ onClick }
			ref={ LinkRef }
			to={ to }
		>
			{ children }
		</Link>
	);
};

NavLink.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	to: PropTypes.string.isRequired
};

export default NavLink;
